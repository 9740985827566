<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useCurrencyStore } from "~/stores/currency";

const props = defineProps({
  priceBeforeDiscount: {
    type: Number,
    default: 0,
  },
  displayPrice: {
    type: Number,
    default: 0,
  },
});

const percentage = computed(() => {
  if (!props.priceBeforeDiscount) {
    return 0;
  }

  var sale_price = props.priceBeforeDiscount - props.displayPrice;
  return Math.round((sale_price * 100) / props.priceBeforeDiscount);
});
const priceNet = computed(()=>{
    return Math.round(props.priceBeforeDiscount)
})
const price = computed(()=>{
    return Math.round(props.displayPrice)
})
const { currency } = storeToRefs(useCurrencyStore());
</script>
<template>
  <div class="flex flex-col">
    <div
      v-if="percentage"
      class="my-1 flex flex-wrap items-center space-x-1 text-xs font-bold lg:justify-end"
    >
      <span
        class="max-w-full truncate rounded-lg bg-red-100 px-2 py-0.5 text-red-800 dark:text-red-500 lg:py-1"
        >-{{ percentage }}%</span
      >
    </div>
    <div class="-ml-1 -mt-1 flex flex-wrap items-baseline lg:justify-end">
      <div class="ml-1 mt-1" v-if="priceBeforeDiscount">
        <div class="text-xs text-red-800  dark:text-red-500 line-through">
          <template v-if="currency == 'VND'"> ₫ </template>
          <template v-else> $ </template>
          {{ priceNet.toLocaleString() }}
        </div>
      </div>
      <div class="ml-1 mt-1">
        <div
          class="whitespace-nowrap text-xl font-bold text-secondary-900 dark:text-secondary-400 sm:text-2xl"
        >
          <template v-if="currency == 'VND'"> ₫ </template>
          <template v-else> $ </template>
          {{ price.toLocaleString() }}
        </div>
      </div>
    </div>
  </div>
</template>
